export default {
    "Fatturazione": "Fatturazione",
    "rossoCandidatura": "Rosso: Candidatura non accettata",
    "gialloCandidatura": "Giallo: Candidatura in validazione",
    "verdeCandidatura": "Verde: Candidatura valida",
    "grigioDocumenti": "Grigio: Documenti non ancora richiesti",
    "verdeDocumenti": "Verde: Documenti richiesti forniti",
    "gialloDocumenti": "Giallo: Documenti richiesti non ancora forniti",
    "grigioEdizione": "Grigio: Edizione non ancora definibile",
    "gialloEdizione": "Giallo: Edizione da confermare",
    "verdeEdizione": "Verde: Edizione confermata",
    "gialloLista": "Giallo: Lista d'attesa",
    "grigioIscrizione": "Grigio: Iscrizione in attensa di conferma",
    "gialloIscrizione": "Giallo: Iscrizione da effettuare",
    "verdeIscrizione": "Verde: Iscrizione completata",
    "grigioPagamento": "Grigio: Pagamento non ancora richiesto",
    "verdePagEff": "Verde: Pagamento effettuato",
    "gialloPagamento": "Giallo: Pagamento da effettuare",
    "grigio": "Grigio: ancora in corso",
    "InCorso": "In Corso",
    "NonAccettata": "Non Accettata",
    "rosso": "Rosso: Non perfezionata",
    "verde": "Verde: Completata",
    "Lezione": "Lezione",
    "Tipo": "Tipo",
    "Entro": "Entro",
    "Caricato": "Caricato",
    "Fattura": "Fattura",
    "Importo": "Importo",
    "Scadenza": "Scadenza",
    "Pagatail": "Pagata il",
    "Candidatura": "Candidatura",
    "Data": "Data",
    "Stato": "Stato",
    "Oggetto": "Oggetto",
    "Foto": "Foto",
    "Corso": "Corso",
    "cambialingua": "English Version",
    "registrati": "REGISTRATI",
    "title": "L’ESEMPIO E’ LA PIU’ ALTA FORMA DI INSEGNAMENTO",
    "subtitleHome": "Benvenuto in Alma",
    "subtitleRicette": "Ricette e News",
    "subtitleFacilitazioni": "Facilitazioni allo studio",
    "subtitleInfo": "Informazioni Generali",
    "subtitleAlma": "My ALMA Life",
    "subtitleHousing": "My ALMA Housing",
    "subtitleAssistenza": "Assistenza",
    "subtitleStudente": "Area Studenti",
    "subtitleDidattica": "Didattica",
    "subtitleExtraDidattica": "Extra Didattica",
    "subtitleDiplomati": "Diplomati",
    "subtitleCandidati": "CANDIDATI",
    "subtitleCandidatura": "Area Candidatura",
    "subtitleStoricoCandidature": "Storico Candidature",
    "subtitleProfiloPersonale": "Profilo Personale",
    "subtitleELearning": "E-learning | Materiali Didattici",
    "subtitleInterventi": "I tuoi ticket", // "Richiesta interventi di manutenzione",
    "subtitleCorsi": "Corsi e Open Day",
    "subtitlePrimoGiorno": "Primo Giorno",
    "subtitleSponsor": "I Partner",
    "subtitleChiediAssistenza": "Assistenza",
    "messaggioChiediAssistenza": "Se hai deciso di candidarti o ti stai candidando e hai difficoltà contatta questi riferimenti",
    "emailChiediAssistenza": "E-Mail",
    "telefonoChiediAssistenza": "Telefono",
    "subtitleRegolamento": "Regolamento",
    "regolamentoHousing": "Regolamento ALMA Housing",
    "subtitleRegolamentoHousing": "Info Housing",
    "USERNAME": "USERNAME",
    "NuovaRichiesta": "Nuova Richiesta",
    "IndirizzoMail": "Indirizzo E-Mail",
    "E-Mail": "E-Mail",
    "E-Mail(ripetere)": "E-Mail (ripetere)",
    "cambiaMail": "Cambia E-Mail",
    "nuovaMail": "Nuova E-Mail",
    "confermaMail": "Conferma E-Mail",
    "Salva": "Salva",
    "Annulla": "Annulla",
    "Invia": "Invia",
    "CambiaPassword": "Cambia Password",
    "Passwordattuale": "Password attuale",
    "PrecedentePassword": "Precedente Password",
    "NuovaPassword": "Nuova Password",
    "ConfermaPassword": "Conferma Password",
    "SuccessRequest":"Nuova Password impostata!",
    "InvalidToken":"Link recupero password non più valido!",
    "PasswordsNoMatch":"Le due password non combaciano!",
    "ModificaPasswordLabel": "Se hai dimenticato la password puoi modificarla nella sezione Profilo Personale una volta conclusa la candidatura.",
    "InvalidRequest":"Errore nel processo, contattare la segreteria!",
    "Datidiaccesso": "Dati di accesso",
    "DatiPersonali": "Dati Personali",
    "Nome": "Nome",
    "Cognome": "Cognome",
    "NomeCognome": "Nome Cognome",
    "Telefono": "Telefono",
    "Anagrafica": "Anagrafica",
    "MailSecondaria": "E-Mail Secondaria",
    "Emailalternativa": "Email alternativa",
    "Cellulare": "Cellulare",
    "AltroCellulare": "Altro Cellulare",
    "NumeroAlternativo": "Numero Alternativo",
    "DatidiNascita": "Dati di Nascita",
    "CodiceFiscale": "Codice Fiscale",
    "Sesso": "Sesso",
    "DatadiNascita": "Data di Nascita",
    "CittàdiNascita": "Città di Nascita",
    "Città": "Città",
    "ProvinciadiNascita": "Provincia di Nascita",
    "Scegliunaopzione": "Scegli una opzione",
    "Opzione1": "Opzione 1",
    "Opzione2": "Opzione 2",
    "NazionediNascita": "Nazione di Nascita",
    "Nazione": "Nazione",
    "NazioneRilascio": "Nazione di rilascio",
    "Cittadinanza": "Cittadinanza",
    "SecondaCittadinanza": "Seconda Cittadinanza",
    "Residenza": "Residenza",
    "Indirizzo": "Indirizzo",
    "Indietro": "Indietro",
    "IndirizzoResidenza": "Indirizzo Residenza",
    "CittàdiResidenza": "Città di Residenza",
    "CAP": "CAP",
    "ProvinciadiResidenza": "Provincia di Residenza",
    "NazionediResidenza": "Nazione di Residenza",
    "Percorsodistudi": "Percorso di studi",
    "QualificaoTitolodiStudio": "Qualifica o Titolo di Studio",
    "Annoconseguimentoqualifica": "Anno conseguimento qualifica",
    "Annoqualifica": "Anno qualifica",
    "NomeIstituto": "Nome Istituto",
    "Istituto": "Istituto",
    "CittàIstituto": "Città Istituto",
    "ProvinciaIstituto": "Provincia Istituto",
    "NazioneIstituto": "Nazione Istituto",
    "FrequentatoAlma": "Hai frequentato altre scuole di cucina prima di Alma?",
    "Si": "Si",
    "ConosciutoAlma": "Come hai conosciuto Alma?",
    "AltraScuolaCucina": "Quale scuola di cucina hai frequentato?",
    "Social": "Seguo Alma sui Social",
    "Passato": "Ho partecipato ad un corso in passato",
    "DatidiFatturazione": "Dati di fatturazione",
    "RagioneSociale": "Ragione Sociale",
    "PartitaIVA": "Partita IVA",
    "P.IVA": "P.VAT",
    "CodiceSDI": "Codice SDI",
    "FatturazioneElettronica": "Fatturazione Elettronica",
    "Provincia": "Provincia",
    "Paese": "Paese",
    "Disconnessione": "Disconnessione",
    "StatoCandidatura": "Stato Candidatura",
    "CompletamentoIscrizione": "In questa sezione puoi completare il tuo processo di iscrizione al corso prescelto e  controllare lo stato di approvazione/rifiuto di ogni singolo passaggio",
    "IdoneitàCandidato": "Questa icona rappresenta l'idoneità del candidato",
    "Documenti": "Documenti",
    "StatoDocumenti": "Questa icona rappresenta lo stato dei documenti richiesti",
    "Edizione": "Edizione",
    "ConfermaEdizione": "Questa icona rappresenta la conferma della edizione del corso",
    "Iscrizione": "Iscrizione",
    "DocumentazioneIscrizione": "Questa icona rappresenta la documentazione di iscrizione",
    "StatoPagamento": "Stato Pagamento",
    "StatoPagamenti": "Questa icona rappresenta lo stato dei pagamenti",
    "Pagamenti": "Pagamenti",
    "Completata": "Completata",
    "Tesi": "Esame finale",
    "BuoniOrd": "Buoni d'ordine materie prime e attrezzature per esame finale",
    "NoDocumento": "Non è stato caricato alcun documento di esame",
    "CaricaTesi": "Carica Tesi",
    "ModificaTesi": "Modifica Tesi",
    "TesiInt": "Gli studenti internazionali dovranno caricare solo il PDF work",
    "Modifica": "Modifica",
    "Limite":"carica un file < 4.5 MB",
    "AggiungiPiatti": "Aggiungi Piatti",
    "Nomedelpiatto": "Nome del piatto",
    "TotalePiatto": "Totale Piatto",
    "InserisciPiatto": "Inserisci nome del piatto",
    "Inserisci": "Inserisci ",
    "InserisciTitolo": "Inserisci Titolo",
    "InserisciDescrizione": "Inserisci Descrizione",
    "ProcessoIscrizione": "Questa icona rappresenta lo stato complessivo del processo di iscrizione",
    "Quantità": "Quantità",
    "Attrezzatura": "Attrezzatura",
    "Ingredienti": "Ingredienti",
    "IdentificaRuolo": "Specifica il tuo profilo",
    "StudenteIPSAR": "Studente IPSSAR",
    "Genitore": "Genitore",
    "ProfessionistaHORECA": "Professionista HORECA",
    "DocenteIPSAR": "Docente IPSSAR",
    "CandidatoAlma": "Candidato Alma",
    "StudenteAlma": "Studente Alma",
    "DiplomatoAlma": "Diplomato Alma",
    "Inserisciemail": "Inserisci email",
    "Inserisciun": "Inserisci Username",
    "TestoRegistrazione": "Inserisci ora la tua mail come username e una password a tua scelta per registrarti alla tua area personale e procedere nella candidatura",
    "Installazione": "Vuoi installare la webapp?",
    "Installa": "Installa",
    "CaricaDocumento": "Carica il documento",
    "ScegliFile": "Scegli il file da caricare",
    "Filecaricare": "Carica il file:",
    "FilecaricareLabel": "E' consigliato il formato PDF, non oltre 4.5 MB",
    "FilecaricareDocLabel": "E' consigliato il formato PDF o JPEG, non oltre 4.5 MB",
    "Dimensioni": "Dimensioni:",
    "DimensioniEccessive": "La dimensione del file supera il limite massimo consentito, anche se il limite è indicativo riduci la dimensione del file.",
    "Carica": "Carica",
    "NomeFileTesi": "Nome del file dell'esame caricato:",
    "Studi": "Studi",
    "InserireCurriculum": "Inserire Curriculum",
    "CaricaCurriculum": "Carica Curriculum",
    "Letuecredenziali": "Le tue credenziali",
    "ATTENZIONE": "ATTENZIONE!",
    "SCARICAMODELLOCV": "SCARICA MODELLO CV",
    "INIZIA": "INIZIA",
    "Fine": "Fine!",
    "Successivo": "Successivo",
    "Afghanistan":"Afghanistan",
    "Albania":"Albania",
    "Algeria":"Algeria",
    "Samoa Americane":"Samoa Americane",
    "Andorra":"Andorra",
    "Angola (compresa Cabinda)":"Angola (compresa Cabinda)",
    "Antigua E Barbuda":"Antigua E Barbuda",
    "Argentina":"Argentina",
    "Armenia":"Armenia",
    "Australia":"Australia",
    "Austria":"Austria",
    "Azerbaigian":"Azerbaigian",
    "Bahamas":"Bahamas",
    "Bahrein":"Bahrein",
    "Bangladesh":"Bangladesh",
    "Barbados":"Barbados",
    "Belarus":"Belarus",
    "Belgio":"Belgio",
    "Belize":"Belize",
    "Bhutan":"Bhutan",
    "Bolivia":"Bolivia",
    "Bosnia-erzegovina":"Bosnia-erzegovina",
    "Botswana":"Botswana",
    "Brasile":"Brasile",
    "Brunei":"Brunei",
    "Bulgaria":"Bulgaria",
    "Burkina-faso":"Burkina-faso",
    "Burundi":"Burundi",
    "Cambogia":"Cambogia",
    "Camerun":"Camerun",
    "Canada":"Canada",
    "Capo Verde":"Capo Verde",
    "Repubblica Centrafricana":"Repubblica Centrafricana",
    "Ciad":"Ciad",
    "Cile":"Cile",
    "Cina":"Cina",
    "Colombia":"Colombia",
    "Comore":"Comore",
    "Congo (repubblica)":"Congo (repubblica)",
    "Costarica":"Costarica",
    "Costa D'avorio":"Costa D'avorio",
    "Croazia":"Croazia",
    "Cuba":"Cuba",
    "Cipro":"Cipro",
    "Repubblica Ceca":"Repubblica Ceca",
    "Danimarca":"Danimarca",
    "Dominica":"Dominica",
    "Repubblica Dominicana":"Repubblica Dominicana",
    "Ecuador":"Ecuador",
    "Egitto":"Egitto",
    "El Salvador":"El Salvador",
    "Guinea Equatoriale":"Guinea Equatoriale",
    "Eritrea":"Eritrea",
    "Estonia":"Estonia",
    "Etiopia":"Etiopia",
    "Figi":"Figi",
    "Finlandia":"Finlandia",
    "Francia (compreso Monaco E Dipartimenti Oltemare)":"Francia (compreso Monaco E Dipartimenti Oltemare)",
    "Gabon":"Gabon",
    "Gambia":"Gambia",
    "Georgia":"Georgia",
    "Germania":"Germania",
    "Ghana":"Ghana",
    "Grecia":"Grecia",
    "Grenada":"Grenada",
    "Guatemala":"Guatemala",
    "Guinea":"Guinea",
    "Guinea Bissau":"Guinea Bissau",
    "Guyana":"Guyana",
    "Haiti":"Haiti",
    "Honduras (compreso Isole Swan)":"Honduras (compreso Isole Swan)",
    "Hong Kong":"Hong Kong",
    "Ungheria":"Ungheria",
    "Islanda":"Islanda",
    "India":"India",
    "Indonesia":"Indonesia",
    "Iran":"Iran",
    "Iraq":"Iraq",
    "Irlanda":"Irlanda",
    "Israele":"Israele",
    "Italia":"Italia",
    "Giamaica":"Giamaica",
    "Giappone":"Giappone",
    "Giordania":"Giordania",
    "Kazakistan":"Kazakistan",
    "Kenya":"Kenya",
    "Kiribati":"Kiribati",
    "Kuwait":"Kuwait",
    "Kirghizistan":"Kirghizistan",
    "Laos":"Laos",
    "Lettonia":"Lettonia",
    "Libano":"Libano",
    "Lesotho":"Lesotho",
    "Liberia":"Liberia",
    "Libia":"Libia",
    "Liechtenstein":"Liechtenstein",
    "Lituania":"Lituania",
    "Lussemburgo":"Lussemburgo",
    "Macao":"Macao",
    "Macedonia, the former Yugoslav Republ. Of":"Macedonia, the former Yugoslav Republ. Of",
    "Madagascar":"Madagascar",
    "Malawi":"Malawi",
    "Malaysia Peninsulare Ed Orientale":"Malaysia Peninsulare Ed Orientale",
    "Maldive":"Maldive",
    "Mali":"Mali",
    "Malta (gozo, Comino)":"Malta (gozo, Comino)",
    "Isole Marshall":"Isole Marshall",
    "Mauritania":"Mauritania",
    "Maurizio Isola":"Maurizio Isola",
    "Messico":"Messico",
    "Federazione Degli Stati Di Micronesia":"Federazione Degli Stati Di Micronesia",
    "Moldavia":"Moldavia",
    "Monaco":"Monaco",
    "Mongolia":"Mongolia",
    "Marocco":"Marocco",
    "Mozambico":"Mozambico",
    "Myanmar":"Myanmar",
    "Namibia":"Namibia",
    "Nauru":"Nauru",
    "Nepal":"Nepal",
    "Antille Olandesi":"Antille Olandesi",
    "Nuova Zelanda":"Nuova Zelanda",
    "Nicaragua (compreso Isole Corn)":"Nicaragua (compreso Isole Corn)",
    "Niger":"Niger",
    "Nigeria":"Nigeria",
    "Norvegia (compresa Jan Mayen;escluso Arc.svalbard)":"Norvegia (compresa Jan Mayen;escluso Arc.svalbard)",
    "Oman":"Oman",
    "Pakistan":"Pakistan",
    "PALAU":"PALAU",
    "Panama":"Panama",
    "Papuasia - Nuova Guinea":"Papuasia - Nuova Guinea",
    "Paraguay":"Paraguay",
    "Peru'":"Peru'",
    "Filippine":"Filippine",
    "Polonia":"Polonia",
    "Portogallo":"Portogallo",
    "Qatar":"Qatar",
    "Romania":"Romania",
    "Russia":"Russia",
    "Ruanda":"Ruanda",
    "San Cristoforo E Nevis":"San Cristoforo E Nevis",
    "Saint Lucia":"Saint Lucia",
    "Saint Vincent":"Saint Vincent",
    "Samoa Occidentali":"Samoa Occidentali",
    "San Marino":"San Marino",
    "Sao Tome' E Principe":"Sao Tome' E Principe",
    "Arabia Saudita":"Arabia Saudita",
    "Senegal":"Senegal",
    "Seychelles E Dipendenze":"Seychelles E Dipendenze",
    "Sierra Leone":"Sierra Leone",
    "Singapore":"Singapore",
    "Slovacchia":"Slovacchia",
    "Slovenia":"Slovenia",
    "Isole Salomone":"Isole Salomone",
    "Somalia":"Somalia",
    "Sud Africa":"Sud Africa",
    "Spagna (compr.baleari,canarie,ceuta,melilla)":"Spagna (compr.baleari,canarie,ceuta,melilla)",
    "Sri Lanka":"Sri Lanka",
    "Sudan":"Sudan",
    "Suriname":"Suriname",
    "Svezia":"Svezia",
    "Svizzera":"Svizzera",
    "Siria":"Siria",
    "Taiwan":"Taiwan",
    "Tanzania (tanganica, Zanzibar, Penba)":"Tanzania (tanganica, Zanzibar, Penba)",
    "Thailandia":"Thailandia",
    "Togo":"Togo",
    "Tonga":"Tonga",
    "Trinidad E Tobago":"Trinidad E Tobago",
    "Tunisia":"Tunisia",
    "Turchia":"Turchia",
    "Turkmenistan":"Turkmenistan",
    "Tuvalu":"Tuvalu",
    "Uganda":"Uganda",
    "Ucraina":"Ucraina",
    "Emirati Arabi Uniti":"Emirati Arabi Uniti",
    "Regno Unito (Gran Bretagna, Irlanda Nord)":"Regno Unito (Gran Bretagna, Irlanda Nord)",
    "Stati Uniti d'America":"Stati Uniti d'America",
    "Uruguay":"Uruguay",
    "Uzbekistan":"Uzbekistan",
    "Vanuatu":"Vanuatu",
    "Citta' Del Vaticano":"Citta' Del Vaticano",
    "Venezuela":"Venezuela",
    "Vietnam":"Vietnam",
    "Yemen":"Yemen",
    "Serbia":"Serbia",
    "Zambia":"Zambia",
    "Zimbabwe":"Zimbabwe",
    "Montenegro":"Montenegro",
    "Palestinian Territory, Occupied":"Palestinian Territory, Occupied",
    "Timor-Leste":"Timor-Leste",
    "Repub. Federale Di Jugoslavia (serbia,montenegro)":"Repub. Federale Di Jugoslavia (serbia,montenegro)",
    "Apolide":"Apolide",
    "Scaduto":"Scaduto",
    "Valido":"Valido",
    "Richiesto":"Richiesto",
    "Non valido":"Non valido",
    "In attesa di validazione":"In attesa di validazione",
    "Motivazione":"Motivazione",
    "Dati Accesso":"Dati Accesso",
    "Dati Anagrafici":"Dati Anagrafica",
    "Fatturazione Housing":"Fatturazione Housing",
    "Fatturazione Corsi":"Fatturazione Corsi",
    "MeStesso":"Me Stesso",
    "PersonaGiuridica":"Azienda o Impresa",
    "PersonaFisica":"Altra Persona",
    "Intestatario":"Intestatario",
    "Abbigliamento":"Abbigliamento",
    "guidaTaglie":"Guida alle taglie",
    "TagliaGiacca":"Taglia Giacca",
    "TagliaBomber":"Taglia Bomber",
    "TagliaPantalone":"Taglia Pantalone",
    "TagliaPolo":"Taglia Polo",
    "TagliaScarpe":"Taglia Scarpe",
    "TagliaGilet":"Taglia Gilet",
    "TagliaCamicia":"Taglia Camicia",
    "Conferma":"Conferma",
    "Documento di riconoscimento":"Documento di riconoscimento",
    "TipoDocumento":"Tipo Documento",
    "Numero":"Numero",
    "DataRilascio":"Data di Rilascio",
    "DataScadenza":"Data di Scadenza",
    "EnteRilascio":"Ente di Rilascio",
    "DatiCauzione":"Dati per la restituzione cauzione",
    "DatiLegRap":"Dati del legale rappresentante",
    "NomeLegRap":"Nome Legale Rappresentante",
    "CognomeLegRap":"Cognome Legale Rappresentante",
    "MailLegRap":"Mail Legale Rappresentante",
    "NotifyTitle": "Notifiche",
    "AttivaNotifiche": "Attiva Notifiche",
    "DisattivaNotifiche": "Disattiva Notifiche",
    "RegolamentoDidattica": "Regolamento Didattica",
    "ProtocolloCovid": "Protocollo Covid",
    "Accettata": "Accettata",
    "Rifiutata": "Rifiutata",
    "In attesa di approvazione": "In attesa di approvazione",
    "In Fase di Selezione": "In Fase di Selezione",
    "Conferma Email":"Conferma E-Mail",
    "Altro":"Altro",
    "boxesHome1": {
        "alttext": "Informazioni",
        "title": "Info Generali",
        "menulist": ["Perché Alma", "Corsi e Open Day", "Ricette e News", "Finanziamenti e sconti"]
    },
    "boxesCorsi1": {
        "alttext": "Corsi",
        "title": "Corsi",
        "extlink": "https://www.alma.scuolacucina.it/corsi/"
    },
    "boxesRicette1": {
        "alttext": 'Ricette',
        "title": 'Ricette',
        "extlink": ""
    },
    "boxesFacilitazioni1": {
        "alttext": 'Cooking quiz',
        "title": 'Cooking quiz',
        "extlink": ""
    },
    "boxesInfo1": {
        "alttext": 'Perché scegliere Alma',
        "title": 'Perché scegliere Alma',
        "extlink": 'https://www.alma.scuolacucina.it/perche-scegliere-alma/'
    },
    "boxesHousing1": {
        "alttext": 'My Housing',
        "title": 'My Housing'
    },
    "boxesAssistenza1": {
        "alttext": 'Chiedi Informazioni',
        "title": 'Chiedi Informazioni',
        "extlink": 'https://customer.alma.scuolacucina.it/iscrizioni/chiedi_informazioni'
    },
    "boxesStudente1": {
        "alttext": 'Regolamento',
        "title": 'Regolamento'
    },
    "boxesDidattica1": {
        "alttext": 'hyperplanning',
        "title": 'Hyperplanning'
    },
    "boxesDiplomati1": {
        "alttext": "AlmaLink",
        "title": "AlmaLink",
        "extlink": 'https://www.alma.scuolacucina.it/diplomati/'
    },
    "boxesExtraDidattica1": {
        "alttext": 'Candidati agli eventi',
        "title": 'Candidati agli eventi'
    },
    "boxesCandidati1": {
        "alttext": 'Scegli un corso',
        "title": 'Scegli un corso',
        "menulist": [''],
        // "menulist": ['Scegli il tuo corso e invia attraverso il sito la tua candidatura'],
        "extlink": 'https://www.alma.scuolacucina.it/corsi/'
    },
    "boxesSponsor1": {
        "alttext": "Le Ricette",
        "title":   "Le Ricette",
        "extlink": "https://www.alma.scuolacucina.it/sostenibilita-in-cucina-ricette-partner/"
    },
    "boxesHome2": {
        "alttext": "Area Candidatura in ALMA",
        "title": "Area Candidatura",
        "menulist": ["Scegli un corso", "Stato Candidatura", "Assistenza", "Storico Candidature"]
    },
    "boxesCorsi2": {
        "alttext": "Open Day",
        "title": "Open Day",
        "extlink": "https://www.alma.scuolacucina.it/categoria-eventi/open-day/"
    },
    "boxesRicette2": {
        "alttext": "Vini & degustazioni",
        "title": "Vini & degustazioni",
        "extlink": ""
    },
    "boxesFacilitazioni2": {
        "alttext": "Prestiti d'onore",
        "title": "Prestiti d'onore",
        "extlink": ""
    },
    "boxesInfo2": {
        "alttext": 'Corsi e Open Day',
        "title": 'Corsi e Open Day',
        "extlink": ''
    },
    "boxesAlma2": {
        "alttext": 'Primo Giorno',
        "title": 'Primo Giorno',
    },
    "boxesHousing2": {
        "alttext": 'Regolamento',
        "title": 'Regolamento Descrizione Alloggi Gestione Rifiuti'
    },
    "boxesAssistenza2": {
        "alttext": 'Segreteria Studenti',
        "title": 'Segreteria Studenti',
        "extlink": 'https://www.alma.scuolacucina.it/segreteria-studenti'
    },
    "boxesStudente2": {
        "alttext": 'Utility',
        "title": 'Utility',
    },
    "Armadietto": "Armadietto",
    "assegnatoFinoAl": "assegnato fino al",
    "boxesDidattica2": {
        "alttext": 'eLearning',
        "title": 'eLearning',
    },
    "boxesDiplomati2": {
        "alttext": "Corsi di aggiornamento",
        "title": "Corsi di aggiornamento",
        "extlink": 'https://www.alma.scuolacucina.it/corsi/corsi-di-aggiornamento-professionale/'
    },
    "boxesExtraDidattica2": {
        "alttext": 'Eventi Locali',
        "title": 'Eventi Locali'
    },
    "boxesCandidati2": {
        "alttext": 'Stato Candidatura',
        "title": 'Stato Candidatura',
        "menulist": [''],
        //  "menulist": ['Verifica lo stato della tua candidatura'],
        "extlink": ""
    },
    "boxesSponsor2": {
        "alttext": "I Webinar",
        "title":   "I Webinar",
        "extlink": "https://www.alma.scuolacucina.it/webinar-e-masterclass/"
    },
    "boxesHome3": {
        "alttext": "My Alma Life",
        "title": "My Alma Life",
        "menulist": ["Regolamento", "Primo giorno", "Area studente", "Housing"]
    },
    "boxesCorsi3": {
        "alttext": "Contattaci",
        "title": "Contattaci",
        "extlink": "https://customer.alma.scuolacucina.it/iscrizioni/chiedi_informazioni?_ga=2.77352279.318091620.1591870301-1221427990.1521545101"
    },
    "boxesRicette3": {
        "alttext": 'Il Pane D\'Italia con ALMA',
        "title": 'Il Pane D\'Italia con ALMA',
        "extlink": ""
    },
    "boxesFacilitazioni3": {
        "alttext": 'Facilitazioni IPSSAR',
        "title": 'Facilitazioni IPSSAR',
        "extlink": " https://www.alma.scuolacucina.it/facilitazioni-di-pagamento/"
    },
    "boxesInfo3": {
        "alttext": 'Ricette e News',
        "title": 'Ricette e News',
        "extlink": ""
    },
    "boxesAlma3": {
        "alttext": 'Area studente',
        "title": 'Area studente',
        "extlink": ""
    },
    "boxesHousing3": {
        "alttext": 'Interventi',
        "title": 'Interventi'
    },
    "boxesAssistenza3": {
        "alttext": 'Segnalazioni',
        "title": '(Segnalazioni)'
    },
    "boxesStudente3": {
        "alttext": 'Didattica',
        "title": 'Didattica'
    },
    "boxesDidattica3": {
        "alttext": 'Presenze',
        "title": 'Presenze'
    },
    "boxesDiplomati3": {
        "alttext": "Eventi Alma",
        "title": "Eventi Alma",
        "extlink": 'https://www.alma.scuolacucina.it/eventi/'
    },
    "boxesExtraDidattica3": {
        "alttext": 'Convenzioni',
        "title": 'Convenzioni'
    },
    "boxesCandidati3": {
        "alttext": 'Assistenza',
        "title": 'Assistenza',
        "extlink": ''
    },
    "boxesSponsor3": {
        "alttext": "Le News",
        "title":   "Le News",
        "extlink": "https://marketing.scuolacucina.it/le-news-dei-partner-di-alma"
    },
    /*"boxesHome4": {
        "alttext": "Assistenza",
        "title": "Assistenza",
        "menulist": ["Chiedi informazioni", "Segreteria Studenti", "Segnalazioni", "Lavora con noi"]
    },*/
    "boxesCorsi4": {
        "alttext": "Iscriviti",
        "title": "Iscriviti",
        "extlink": "https://www.alma.scuolacucina.it/come-iscriversi/"
    },
    "boxesRicette4": {
        "alttext": 'Ricette',
        "title": 'Ricette',
        "extlink": 'https://www.alma.scuolacucina.it/le-ricette-firmate-alma/'
    },
    "boxesInfo4": {
        "alttext": 'Finanziamenti e Sconti',
        "title": 'Finanziamenti e Sconti',
        "extlink": "https://www.alma.scuolacucina.it/facilitazioni-di-pagamento/"
    },
    "boxesAlma4": {
        "alttext": 'Housing',
        "title": 'Housing',
        "extlink": ""
    },
    "boxesHousing4": {
        "alttext": 'Informazioni per emergenza',
        "title": 'Informazioni per emergenza'
    },
    "boxesAssistenza4": {
        "alttext": 'Lavora con noi',
        "title": 'Lavora con noi',
        "extlink": 'https://www.alma.scuolacucina.it/lavora-con-noi'
    },
    "boxesStudente4": {
        "alttext": 'Extra Didattica',
        "title": 'Extra Didattica'
    },
    "boxesDidattica4": {
        "alttext": 'Area Esami Finali',
        "title": 'Area Esami Finali'
    },
    "boxesDidattica5": {
        "alttext": 'Area Esami',
        "title": 'Area Esami'
    },
    'Esami': 'ESAMI',
    'EsamiIntermedi':'Esami Intermedi',
    'EsameIntermedio':'Esame Intermedio',
    "boxesCandidati4": {
        "alttext": 'Storico Candidature',
        "title": 'Storico Candidature',
        "menulist": [''],
        "extlink": ""
    },
    "boxesAlma5": {
        "alttext": 'Diplomati',
        "title": 'Diplomati',
        "extlink": ""
    },
    "boxesStudente5": {
        "alttext": 'Prenotazioni',
        "title": 'Prenotazioni'
    },
    'Prenotazioni': 'Prenotazioni',
    'CosaVuoiPrenotare': 'Cosa vuoi prenotare?',
    'Seleziona': 'Seleziona',
    'Eventi': 'Eventi',
    'CorsiModuli': 'Corsi - Moduli',
    'SessioniEsami': 'Sessioni Esami',
    'Periodo': 'Periodo',
    'EventiDisponibili': 'Eventi disponibili',
    'aperturaIscr': 'Apertura Iscr.',
    'TermineIscr': 'Termine Iscr.',
    'NomeEvento': 'Nome Evento',
    'Luogo': 'Luogo',
    'NMinPers': 'N.minimo persone',
    'PostiDisponibili': 'Posti Disponibili',
    'CorsiModuliDisponibili': 'Corsi e Moduli disponibili',
    'DataInizio': 'Data inizio',
    'DataFine': 'Data fine',
    'NomeModulo': 'Nome Modulo',
    'NomeEsame': 'Nome Esame',
    'EsamiDisponibili': 'Esami disponibili',
    "boxesRicette5": {
        "alttext": 'News',
        "title": 'News',
        //"extlink": 'https://www.alma.scuolacucina.it/en/case-history/'
    },
    "boxesAlma6": {
        "alttext": "I Partner",
        "title":   "I Partner",
        "extlink": ""
    },
    "descrizioneAlloggio": "Descrizione Alloggio",
    "CaricaDocumentoIdentita":"Carica Documento",
    "rifiuti":"Memo rifiuti",
    "HaiUnaLogin":"Hai già una login?",
    "subtitleVoucher":"Riscatta il tuo voucher:",
    "AttualeOccupazione":"Attuale Occupazione",
    "OperatoreCucina":"Operatore di Cucina",
    "OperatorePasticceria":"Operatore di Pasticceria",
    "OperatoreSala":"Operatore di Sala",
    "FBManager":"F&B Manager",
    "AttivitàRistorative":"Titolare di attività ristorative",
    "IndirizzodiStudio":"Indirizzo di Studio",
    "bloccomodifiche":"Non è più possibile apportare modifiche all'esame",
    "CaricaDossier" : "Carica Dossier",
    "DossierInt": "Carica registro presenze e scheda valutazione",
    "ModificaDossier":"Modifica Dossier",
    "NoDocumentoDossier":"Non è stato caricato alcun Dossier del Tirocinante",
    "NomeFileDossier":"Nome del file caricato:",
    "GuidaMaterie":"Guida Materie",
    "UnitaMisura":"Unità di misura",
    "NomeIng": "Nome",
    "NoIng": "Nessun ingrediente richiesto",
    "NoAttr": "Nessuna attrezzatura richiesta",
    "NeedNo": "Non ho bisogno di ",
    "RegioneIstituto":"Regione Istituto",
    "TipoPiatto" : "Tipo Piatto",
    "Categoria":"Categoria",
    "Antipasto":"Antipasto",
    "Primo piatto":"Primo piatto",
    "Secondo piatto":"Secondo piatto",
    "Dolce":"Dolce",
    "Stuzzichino":"Stuzzichino",
    "Piatto unico":"Piatto unico",
    "Torta":"Torta",
    "Dessert monoporzione":"Dessert monoporzione",
    "Dessert":"Dessert",
    "Mignon":"Mignon",
    "Dessert al piatto":"Dessert al piatto",
    "Produzione pane di libera ispirazione":"Produzione pane di libera ispirazione",
    "Prodotto da colazione sfogliato o lievitato":"Prodotto da colazione sfogliato o lievitato",
    "Biscotteria":"Biscotteria",
    "Focaccia salata":"Focaccia salata",
    "loggato":"ATTENZIONE: sei loggato con un utente, i dati che stai inserendo sovrascriveranno quelli già presenti",

    /* E-COMMERCE */
    "subtitleAcquisti": "Shop",
    "CercaProdotto": "Cerca qualsiasi cosa",
    "Carrello": "Il tuo carrello",
    "Articoli": "Articoli",
    "Riepilogo": "Riepilogo",
    "vuoiUnaBusta" : "Vuoi una busta?",
    "noBusta" : "Non voglio una busta",
    "ImportoTotale": "Importo totale",
    "Pagamento": "Procedi al pagamento",
    "subtitleCommerce": "Ecommerce",
    "boxesCommerce1": {
        "alttext": "Acquista",
        "title":   "Acquista"
    },
    "boxesCommerce2": {
        "alttext": "Storico",
        "title":   "Storico"
    },
    "boxesCommerce3": {
        "alttext": "Ricarica Badge",
        "title":   "Ricarica Badge"
    },
    "boxesHome4": {
        "alttext": "Ecommerce",
        "title": "Ecommerce",
        "menulist": ["Acquista", "Riepilogo", "Ricarica Badge"]
    },
    "Qta": "Qta",
    "RicaricaBadge": "Ricarica Badge",
    "Aggiungicarrello": "Aggiungi al carrello",
    "Acquistorapido": "Acquisto rapido",
    "Importototale": "Importo totale",
    "Acquistaora": "Vai al pagamento",
    "AggiuntoCarrello": "Aggiunto al carrello",
    'tempoApprovvigionamento': 'Tempi di consegna',
    'codiceRicarica': 'Codice Ricarica',

    "Prodotto": "Prodotto",
    "Quantita": "Quantità",
    "PUnita": "Prezzo (unità)",
    "Totale": "Totale",
    "TotaleTesi": "Totale Tesi",
    "TotCart": "Totale finale (IVA inclusa)",
    "ProcediPagamento": "Procedi al pagamento",

    "Prezzo": "Prezzo",
    "Taglia": "Taglia",
    "Idoneo": "Idoneo per",
    "FiltraPer": "Filtra per...",

    "Categorie": "Categorie",
    "Corsi": "Corsi",
    "Reset": "Resetta filtri",
    //"Abbigliamento": "Abbigliamento",
    "Libri": "Libri",
    "Utensili": "Utensili",

    "NewTicket": "Nuovo Ticket",
    "EnterCat": "Inserisci Categoria",
    'InserisciSottocategoria': 'Inserisci Sottocategoria',
    "NeedHelp": "Hai bisogno di aiuto?",
    'Assenze': 'Assenze',
    'ColloquiDirettoreDidattico':'Colloqui con Direttore Didattico',
    'DidatticaVitaResidenziale':'Didattica e Vita residenziale',
    'EsamiFinali':'Esami finali',
    'HyperplanningWebapp' : 'Hyperplanning e Webapp',
    'Iscrizioni':'Iscrizioni',
    'Medico':'Certificazione medica obbligatoria',
    'RichiesteCertificati':'Richieste certificati',
    'CheckFirmaRegistro': 'Check firma registro',
    'RichiestaMonteOreAssenzeAttuale': 'Richiesta monte ore assenze attuale',
    'SegnalazioneProblemaAssenza' : 'Segnalazione problema assenza (assenza inserita per errore/giustifica non inserita)',
    'CalendarioCorsiFestivita': 'Calendario corsi e festività',
    'MasterclassAttivitaScelta': 'Masterclass e attività a scelta',
    'ProblematicaIscrizioneMasterclassAttivita': 'Problematica iscrizione masterclass e attività a scelta',
    'VerificheVisualizzazioneVoto':'Verifiche - Problemi visualizzazione voto verifiche',
    'VerificheVotoDiploma':'Verifiche - Dettagli Voto diploma',
    'MaterialiDidattici': 'Materiali didattici',
    'ColloquiCorpoDocente':'Colloqui con corpo docente',
    'PrenotazioneSpostamentoNuovaSezione':'Prenotazione sessione o richiesta spostamento nuova sezione',
    'ProblematicaTicketWebapp':'Problematica apertura ticket su Webapp',
    'ProblematicheIngredienti':'Problematiche ingredienti',
    'ProblematicheAttrezzature':'Problematiche attrezzature',
    "InfoProveEsame":"Informazioni su programma delle prove d'esame",
    'InfoCerimonieDiplomi':'Informazioni svolgimento cerimonie dei diplomi',
    'SegnalazioneGuasti':'Segnalazione guasti',
    'RichiestaUscitaHousing':'Richiesta di uscita housing anticipata/posticipata a fine corso',
    'SacchiSpazzatura':'Sacchi spazzatura',
    'SegnalazioniVarie':'Segnalazione problematiche varie',
    'PagamentiHousing':'Pagamenti Housing',
    'ProblemiVariHP':'Problemi di accesso Hyperplanning (credenziali errate,...)',
    'ProblemiAccessoWebapp':'Problemi accesso Webapp',
    'InfoPagamenti':'Richieste info pagamenti',
    'ProblCaricaDocMedico':'Problemi di caricamento documentazione app MyAlma Life',
    'Strutturato':'Strutturato',
    'Frequenza':'Frequenza',
    'HaccpSicurezza':'HACCP e SICUREZZA',
    'ProblStageCompilazioneDossier': 'Problematiche durante lo stage - Compilazione Dossier',
    'ProblStageMonteOre': 'Problematiche durante lo stage - Monte ore',
    'ProblStageMansionario': 'Problematiche durante lo stage - Mansionario',
    'RichColloquioPreStage': 'Pre inizio stage - Richiesta colloquio per info dopo consegna stage',
    'ColloquiDirettorePreStage': 'Pre inizio stage - Colloqui con Direttore Didattico',
    'RichiestaCertIscrizione': 'Richiesta certificato di iscrizione (es. per accesso a finanziamenti)',
    'RichiestaCertFrequenza': 'Richiesta certificato frequenza fase residenziale',
    'RichiestaCertSostitutivo':'Richiesta certificato sostitutivo del diploma',
    'ProbCaricaDocEsami':'Problemi di caricamento documenti di esame',
    'ModConsegnaIngr':'Modalità di consegna materie prime',
    'ModConsegnaAttr':'Modalità di consegna attrezzature',
    'InfoStesuraTesi':'Informazioni per stesura Tesi',
    'RichiestaLinkHP':'Richiesta link per accesso nuova base dati Hyperplanning',
    'RichiestaCredenzialiApp':'Richiesta credenziali app MyAlma Life',
    'RichiestaCertAnamnestico':'Richiesta modello di certificato anamnestico',
    'ProblStageAltro':'Problematiche durante lo stage - Altro',
    'InfoOrarioArrivo':'Info orario di arrivo',
    'InfoDateCorsi':'Info Date corsi',

    'Visto':'Visto',
    'AttesaAppAmbasciata':'Attesa data appuntamento ambasciata',
    'ConcessioneVisto':'Concessione visto',
    'InfoVolo':'Informazioni volo',
    'InfoAppAlma':'Informazioni appuntamento in Alma',

    'InfoAppAmbasciata':'Informazioni Appuntamento Ambasciata',
    'DataAppAmbasciata':'Data Appuntamento in Ambasciata',
    'NomeAmbasciata':'Nome Ambasciata',
    'IndirizzoAmbasciata':'Indirizzo Ambasciata',

    'InfoVisto':'Informazioni Visto',
    'DataDecorrenzaVisto':'Data Decorrenza Visto',
    'DataScadenzaVisto':'Data Scadenza Visto',
    'MotivoRifiuto': 'Motivo Rifiuto',

    'DataOraVolo':'Data e Ora di Arrivo',
    'AeroportoArrivo':'Aeroporto di Arrivo',
    'NumBiglietto': 'Numero del biglietto aereo',

    'DataAppAlma':'Data Appuntamento in Alma',
    'DataAppPostaKit':'Data Appuntamento in Posta per il Kit',
    'DataFotoSegnalazione':'Data Foto Segnalazione',

    'AttesaData': 'Verrà fornita una data dall\'ufficio'
}