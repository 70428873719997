
<template>
  <div>
  <div class="row">
    <div class="col"></div>
    <div class="col-sm-6 col-md-6">
      <div style="text-align: center; margin-bottom: 30px">
        <table style="width:100%">
          <tr>
            <td style="width: 33%; padding: 0 20px 0 20px;"><label class="form-check-label fontRadioB">{{$t('Dati Accesso')}}</label></td>
            <td style="width: 33%; padding: 0 20px 0 20px;"><label class="form-check-label fontRadioB">{{$t('Dati Anagrafici')}}</label></td>
            <td v-if="datiestesi" style="width: 33%; padding: 0 20px 0 20px;"><label class="form-check-label fontRadioB">{{$t('Fatturazione')}}</label></td>
          </tr>
          <tr>
            <td style="width: 33%; padding: 0 20px 20px 20px;"><input  v-model="tabDecision" v-on:change="setTabs()" class="form-check-input" type="radio"  value="DatiAccesso"/></td>
            <td style="width: 33%; padding: 0 20px 20px 20px;"><input  v-model="tabDecision" v-on:change="setTabs()" class="form-check-input" type="radio"  value="DatiAnagrafici"/></td>
            <td v-if="datiestesi" style="width: 33%; padding: 0 20px 20px 20px;"><input  v-model="tabDecision" v-on:change="setTabs()" class="form-check-input" type="radio"  value="Fatturazione"/></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="col"></div>
  </div>
  
  <div class="row">
    <div class="col">
          <tabs v-bind:tabs="tabs">

            <template v-slot:dlogin>
              <h3>{{$t('Dati Accesso')}}</h3>
              <div class="col-xs-10 col-sm-10">

                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('USERNAME')}}</label>
                      <input
                        v-model="$store.state.userprofile.Username__pc"
                        maxlength="100"
                        type="text"
                        class="form-control"
                        placeholder="Username"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-10">
                    <hr />
                  </div>
                </div>

                <div class="row align-items-center" v-if="!cambiaemail">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('IndirizzoMail')}}</label>
                      <input v-model="$store.state.userprofile.PersonEmail" maxlength="100" required type="email" class="form-control" placeholder="Email" disabled
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <button type="submit" class="btn btn-primary float-right" v-on:click.prevent="cambiaemail=true">{{$t('cambiaMail')}}</button>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center" v-if="cambiaemail">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group" v-if="cambiaemail">
                      <label class="control-label">{{$t('nuovaMail')}}</label>
                      <input v-model="user.emailnew" maxlength="100" 
                        v-bind:class="checkEmail() || !regEmail.test(user.emailnew)?'is-invalid':'is-valid'"
                        required type="email" class="form-control" placeholder="Email"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group" v-if="cambiaemail">
                      <label class="control-label">{{$t('confermaMail')}}</label>
                      <input v-model="user.emailnew2" 
                        v-bind:class="checkEmail() || !regEmail.test(user.emailnew2)?'is-invalid':'is-valid'"
                        maxlength="100" required type="email" class="form-control" placeholder="Email"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-if="cambiaemail">
                  <div class="col-sm-12 col-md-5">
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <button type="submit" class="btn btn-primary" v-on:click.prevent="doCambiaEmail">{{$t('Salva')}}</button>
                      &nbsp;
                      <button type="submit" class="btn btn-outline-secondary" v-on:click.prevent="cambiaemail=false">{{$t('Annulla')}}</button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-10">
                    <hr />
                  </div>
                </div>

                <div class="row align-items-center" v-if="!cambiapassword">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">Password</label>
                      <div class="input-group">
                        <input value="password" maxlength="100" type="password" class="form-control" :placeholder="$t('Passwordattuale')" disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group" v-if="!cambiapassword">
                      <button type="submit" class="btn btn-primary float-right" v-on:click.prevent="cambiapassword=true">{{$t('CambiaPassword')}}</button>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="cambiapassword">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('PrecedentePassword')}}</label>
                      <div class="input-group">
                        <input v-model="user.pwdold" 
                          v-bind:class="checkPwdOld()?'is-invalid':'is-valid'"
                          maxlength="100" v-bind:type="reveal?'text':'password'" class="form-control" :placeholder="$t('Passwordattuale')"
                        />
                        <div class="input-group-append" v-on:click="chgReveal">
                          <span class="input-group-text">
                            <span v-if="!reveal" class="oi oi-eye"></span>
                            <span v-if="reveal" class="oi oi-code"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="cambiapassword">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('NuovaPassword')}}</label>
                      <div class="input-group">
                        <input v-model="user.pwdnew" 
                          v-bind:class="!user.pwdnew || checkPwd()?'is-invalid':'is-valid'"
                          maxlength="100" v-bind:type="reveal?'text':'password'" class="form-control" placeholder="Password"
                        />
                        <div class="input-group-append" v-on:click="chgReveal">
                          <span class="input-group-text">
                            <span v-if="!reveal" class="oi oi-eye"></span>
                            <span v-if="reveal" class="oi oi-code"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('ConfermaPassword')}}</label>
                      <div class="input-group">
                        <input v-model="user.pwdnew2" 
                          v-bind:class="!user.pwdnew2 || checkPwd()?'is-invalid':'is-valid'"
                          maxlength="100" v-bind:type="reveal?'text':'password'" class="form-control" placeholder="Password"
                        />
                        <div class="input-group-append" v-on:click="chgReveal">
                          <span class="input-group-text">
                            <span v-if="!reveal" class="oi oi-eye"></span>
                            <span v-if="reveal" class="oi oi-code"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="cambiapassword">
                  <div class="col-sm-12 col-md-5">
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <button type="submit" class="btn btn-primary" v-on:click.prevent="doCambiaPwd" >{{$t('Salva')}}</button>
                      &nbsp;
                      <button type="submit" class="btn btn-outline-secondary" v-on:click.prevent="cambiapassword=false">{{$t('Annulla')}}</button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-10">
                    <hr />
                  </div>
                </div>

                <div class="row align-items-center">
                  <div class="col-sm-12 col-md-5 mt-4">
                    <div class="form-group">
                      <label class="control-label">{{$t('NotifyTitle')}}</label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5 mt-2">
                    <div class="form-group">
                      <button class="btn btn-primary float-right" v-on:click="beforerun">{{$t(notifiche)}}</button> 
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:dpersonali>
              <h3>{{$t('DatiPersonali')}}</h3>
              <div class="col-xs-10 col-sm-10">
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Nome')}}</label>
                      <input
                        v-bind:value="$store.state.userprofile.FirstName" v-on:input="setField('FirstName',$event)"
                        maxlength="100" type="text" class="form-control" :placeholder="$t('Nome')"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Cognome')}}</label>
                      <input
                        v-bind:value="$store.state.userprofile.LastName" v-on:input="setField('LastName',$event)"
                        maxlength="100" type="text" class="form-control" :placeholder="$t('Cognome')"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Telefono')}}</label>
                      <input
                        v-bind:value="$store.state.userprofile.Phone" v-on:input="setField('Phone',$event)"
                        maxlength="100" type="tel" class="form-control" :placeholder="$t('Telefono')"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('MailSecondaria')}}</label>
                      <input
                        v-bind:value="$store.state.userprofile.Email__c" v-on:input="setField('Email__c',$event)"
                        maxlength="100" type="email" class="form-control" :placeholder="$t('Emailalternativa')"
                        v-bind:class="!regEmail.test($store.state.userprofile.Email__c)? 'isinvalid':''"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Cellulare')}}</label>
                      <input
                        v-bind:value="$store.state.userprofile.PersonMobilePhone" v-on:input="setField('PersonMobilePhone',$event)"
                        maxlength="100" type="tel" class="form-control" :placeholder="$t('Telefono')"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('AltroCellulare')}}</label>
                      <input
                        v-bind:value="$store.state.userprofile.Phone_2__c" v-on:input="setField('Phone_2__c',$event)"
                        maxlength="100" type="tel" class="form-control" :placeholder="$t('NumeroAlternativo')"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="$store.state.durty">
                <div class="col-sm-12 col-md-5">
                </div>
                <div class="col-sm-12 col-md-5">
                  <button type="submit" class="btn btn-primary" v-on:click.prevent="$emit('saveprofilo')">{{$t('Salva')}}</button>
                  &nbsp;
                  <button type="submit" class="btn btn-outline-secondary" v-on:click.prevent="$emit('cancel')">{{$t('Annulla')}}</button>
                </div>
              </div>
            </template>

            <template v-slot:dnascita v-if="datiestesi">
              <h3>{{$t('DatidiNascita')}}</h3>
              <div class="col-xs-10 col-sm-10">
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CodiceFiscale')}}</label>
                      <input
                        v-bind:value="$store.state.userprofile.CF__c" v-on:input="setField('CF__c',$event)"
                        maxlength="16" type="text" required class="form-control" v-bind:placeholder="$t('CodiceFiscale')"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <legend class="col-form-label">{{$t('Sesso')}}</legend>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="sesso" value="F"
                          v-bind:checked="$store.state.userprofile.Sesso__c=='F'" v-on:input="setField('Sesso__c','F','radio')"
                        />
                        <label class="form-check-label">F</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="sesso" value="M"
                          v-bind:checked="$store.state.userprofile.Sesso__c=='M'" v-on:input="setField('Sesso__c','M','radio')"                        
                        />
                        <label class="form-check-label">M</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('DatadiNascita')}}</label>
                      <input type="date" required class="form-control" 
                        v-bind:value="$store.state.userprofile.Data_di_nascita__c" v-on:input="setField('Data_di_nascita__c',$event)"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CittàdiNascita')}}</label>
                      <input
                        v-bind:value="$store.state.userprofile.Citta_di_nascita__c" v-on:input="setField('Citta_di_nascita__c',$event)"
                        maxlength="100" type="text" required class="form-control" :placeholder="$t('Città')"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group" v-show="show">
                      <label class="control-label">{{$t('ProvinciadiNascita')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-bind:value="$store.state.userprofile.Provincia_di_Nascita__c" v-on:input="setField('Provincia_di_Nascita__c',$event)"
                      >
                        <option v-for="v in province" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('NazionediNascita')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-bind:value="$store.state.userprofile.Nazione_di_nascita__c" v-on:input="setField('Nazione_di_nascita__c',$event)"
                      >
                        <option v-for="v in nazioni" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Cittadinanza')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Cittadinanza')"
                        v-bind:value="$store.state.userprofile.Cittadinanza__c" v-on:input="setField('Cittadinanza__c',$event)"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('SecondaCittadinanza')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Cittadinanza')"
                        v-bind:value="$store.state.userprofile.Cittadinanza_2__c" v-on:input="setField('Cittadinanza_2__c',$event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="$store.state.durty">
                <div class="col-sm-12 col-md-5">
                </div>
                <div class="col-sm-12 col-md-5">
                  <button type="submit" class="btn btn-primary" v-on:click.prevent="$emit('saveprofilo')">{{$t('Salva')}}</button>
                  &nbsp;
                  <button type="submit" class="btn btn-outline-secondary" v-on:click.prevent="$emit('cancel')">{{$t('Annulla')}}</button>
                </div>
              </div>

            </template>

            <template v-slot:residenza v-if="datiestesi">
              <h3>{{$t('Residenza')}}</h3>
              <div class="col-xs-10 col-sm-10">
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Indirizzo')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('IndirizzoResidenza')"
                        v-bind:value="$store.state.userprofile.PersonMailingStreet" v-on:input="setField('PersonMailingStreet',$event)"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CittàdiResidenza')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('CittàdiResidenza')"
                        v-bind:value="$store.state.userprofile.PersonMailingCity" v-on:input="setField('PersonMailingCity',$event)"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CAP')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('CAP')"
                        v-bind:value="$store.state.userprofile.PersonMailingPostalCode" v-on:input="setField('PersonMailingPostalCode',$event)"
                        v-bind:class="!regNumber.test($store.state.userprofile.PersonMailingPostalCode)?'isinvalid':''"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('ProvinciadiResidenza')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-bind:value="$store.state.userprofile.PersonMailingState" v-on:input="setField('PersonMailingState',$event)"
                      >
                        <option v-for="v in province" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('NazionediResidenza')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-bind:value="$store.state.userprofile.PersonMailingCountry" v-on:input="setField('PersonMailingCountry',$event)"
                      >
                        <option v-for="v in nazioni" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5"></div>
                </div>
              </div>
              <div class="row" v-if="$store.state.durty">
                <div class="col-sm-12 col-md-5">
                </div>
                <div class="col-sm-12 col-md-5">
                  <button type="submit" class="btn btn-primary" v-on:click.prevent="$emit('saveprofilo')">{{$t('Salva')}}</button>
                  &nbsp;
                  <button type="submit" class="btn btn-outline-secondary" v-on:click.prevent="$emit('cancel')">{{$t('Annulla')}}</button>
                </div>
              </div>
            </template>

            <template v-slot:studi v-if="datiestesi">
              <h3>{{$t('Percorsodistudi')}}</h3>
              <div class="col-xs-10 col-sm-10">
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('QualificaoTitolodiStudio')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-bind:value="$store.state.userprofile.Titolo_di_studio__c" v-on:input="setField('Titolo_di_studio__c',$event)"
                      >
                        <option v-for="v in titoli" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Annoconseguimentoqualifica')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Annoqualifica')"
                        v-bind:value="$store.state.userprofile.Anno_diploma__c" v-on:input="setField('Anno_diploma__c',$event)"
                        v-bind:class="!regNumber.test($store.state.userprofile.Anno_diploma__c)? 'isinvalid':''"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('NomeIstituto')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Istituto')"
                        v-bind:value="$store.state.userprofile.Nome_Istituto__c" v-on:input="setField('Nome_Istituto__c',$event)"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CittàIstituto')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('CittàIstituto')"
                        v-bind:value="$store.state.userprofile.Citta_Istituto__c" v-on:input="setField('Citta_Istituto__c',$event)"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('NazioneIstituto')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-bind:value="$store.state.userprofile.Nazione_Istituto__c" v-on:input="setField('Nazione_Istituto__c',$event)"
                      >
                        <option v-for="v in nazioni" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label
                        class="control-label"
                        style="padding-right:25px;"
                      >{{$t('FrequentatoAlma')}}</label>
                      <br />
                      <div class="form-check paddingpiu">
                        <input class="form-check-input" type="radio" name="altrescuole" value="Si" 
                          v-bind:checked="$store.state.userprofile.isAltreScuole__c" v-on:input="setField('isAltreScuole__c',true,'radio')"
                        />
                        <label class="form-check-label" style="padding-right:45px;">{{$t('Si')}}</label>
                        <input class="form-check-input" type="radio" name="altrescuole" value="No"
                          v-bind:checked="!$store.state.userprofile.isAltreScuole__c" v-on:input="setField('isAltreScuole__c',false,'radio')"
                         />
                        <label class="form-check-label">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('ConosciutoAlma')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                          v-bind:value="$store.state.userprofile.ConosciutoALMA__c" v-on:input="setField('ConosciutoALMA__c',$event)"
                       >
                        <option v-for="v in conosciutoalma" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5" v-if="$store.state.userprofile.isAltreScuole__c">
                    <div class="form-group">
                      <label class="control-label">{{$t('AltraScuolaCucina')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('AltraScuolaCucina')"
                        v-bind:value="$store.state.userprofile.Altre_Scuole_Cucina__c" v-on:input="setField('Altre_Scuole_Cucina__c',$event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="$store.state.durty">
                <div class="col-sm-12 col-md-5">
                </div>
                <div class="col-sm-12 col-md-5">
                  <button type="submit" class="btn btn-primary" v-on:click.prevent="$emit('saveprofilo')">{{$t('Salva')}}</button>
                  &nbsp;
                  <button type="submit" class="btn btn-outline-secondary" v-on:click.prevent="$emit('cancel')">{{$t('Annulla')}}</button>
                </div>
              </div>
            </template>

            <template v-slot:fatturazione v-if="datiestesi">
              <h3>{{$t('DatidiFatturazione')}}</h3>
              <div v-if="$store.state.userprofile.Opportunit_con_richiesta_dati__c > 0 && $store.state.candidatura[0].Fatturante_compilato__c == false" style="text-align: center; margin-bottom: 30px">
                <table style="width:100%">
                  <tr>
                    <td style="width: 33%; padding: 0 20px 0 20px;"><label class="form-check-label fontRadioB">{{$t('MeStesso')}}</label></td>
                    <td style="width: 33%; padding: 0 20px 0 20px;"><label class="form-check-label fontRadioB">{{$t('PersonaGiuridica')}}</label></td>
                    <td style="width: 33%; padding: 0 20px 0 20px;"><label class="form-check-label fontRadioB">{{$t('PersonaFisica')}}</label></td>
                  </tr>
                  <tr>
                    <td style="width: 33%; padding: 0 20px 20px 20px;"><input v-on:click="setField('Tipo_persona_fatturazione__c', 'MeStesso', 'radio')" v-model="fatturante" class="form-check-input" type="radio"  value="MeStesso"/></td>
                    <td style="width: 33%; padding: 0 20px 20px 20px;"><input v-on:click="setField('Tipo_persona_fatturazione__c', 'PersonaGiuridica', 'radio')" v-model="fatturante" class="form-check-input" type="radio"  value="PersonaGiuridica"/></td>
                    <td style="width: 33%; padding: 0 20px 20px 20px;"><input v-on:click="setField('Tipo_persona_fatturazione__c', 'PersonaFisica', 'radio')" v-model="fatturante" class="form-check-input" type="radio"  value="PersonaFisica"/></td>
                  </tr>
                </table>
              </div>
              <div v-if="fatturante =='PersonaGiuridica'" class="col-xs-10 col-sm-10">
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('RagioneSociale')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('RagioneSociale')"
                          v-model="fatt.ragioneSociale" v-on:input="setField('Ragione_Sociale__c',$event)"
                          v-bind:disabled="checkedit" v-bind:class="!fatt.ragioneSociale?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('PartitaIVA')}}</label>
                      <input maxlength="20" type="text" required class="form-control" :placeholder="$t('P.IVA')"
                          v-model="fatt.piva" v-on:input="setField('PIVA_Fatturante__c',$event)"
                          v-bind:disabled="checkedit" v-bind:class="!fatt.piva?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CodiceFiscale')}}</label>
                      <input maxlength="16" type="text" required class="form-control" :placeholder="$t('CodiceFiscale')"
                          v-model="fatt.codicefiscale" v-on:input="setField('Codice_Fiscale__c',$event)"
                          v-bind:disabled="checkedit" v-bind:class="!fatt.codicefiscale?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CodiceSDI')}}</label>
                      <input maxlength="16" type="text" required class="form-control" :placeholder="$t('FatturazioneElettronica')"
                          v-model="fatt.codicesdi" v-on:input="setField('Codice_SDI__c',$event)"
                          v-bind:disabled="checkedit" v-bind:class="!fatt.codicesdi?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">Email Pec</label>
                      <input maxlength="100" type="text" required class="form-control" placeholder="Email Pec"
                          v-model="fatt.pec" v-on:input="setField('PEC__c',$event)"
                          v-bind:disabled="checkedit" v-bind:class="!fatt.pec || !regEmail.test(fatt.pec)?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('E-Mail')}}</label>
                      <input
                        v-model="fatt.email"
                        v-on:input="setField('Email_Fatturante__c',$event)"
                        maxlength="100"
                        required
                        type="email"
                        class="form-control"
                        v-bind:class="!fatt.email || !regEmail.test(fatt.email)?'is-invalid':'is-valid'"
                        placeholder="Email" v-bind:disabled="checkedit"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Telefono')}}</label>
                      <input
                        v-model="fatt.telefono" 
                        v-on:input="setField('Telefono_Fatturante__c',$event)" 
                        maxlength="100"
                        required
                        type="tel"
                        class="form-control"
                        v-bind:class="!fatt.telefono?'is-invalid':'is-valid'"
                        :placeholder="$t('Telefono')" v-bind:disabled="checkedit"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Indirizzo')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Indirizzo')"
                          v-model="fatt.indirizzo" v-on:input="setField('BillingStreet',$event)"
                          v-bind:disabled="checkedit" v-bind:class="!fatt.indirizzo?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Città')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Città')"
                          v-model="fatt.citta" v-on:input="setField('BillingCity',$event)"
                          v-bind:disabled="checkedit" v-bind:class="!fatt.citta?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CAP')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('CAP')"
                          v-model="fatt.cap" v-on:input="setField('BillingPostalCode',$event)"
                          v-bind:disabled="checkedit" v-bind:class="!fatt.cap || !regNumber.test(fatt.cap)?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Provincia')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fatt.provincia" v-on:input="setField('BillingState',$event)"
                        v-bind:disabled="checkedit" v-bind:class="!fatt.provincia?'is-invalid':'is-valid'"
                      >
                        <option v-for="v in province" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Paese')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fatt.stato" v-on:input="setField('BillingCountry',$event)"
                        v-bind:disabled="checkedit" v-bind:class="!fatt.stato?'is-invalid':'is-valid'"
                      >
                        <option v-for="v in nazioni" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-10">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-10">
                    <h3>{{$t('DatiLegRap')}}</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('NomeLegRap')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('NomeLegRap')"
                            v-model="fatt.nomeLegRap" v-on:input="setField('Nome_Rap_Legale__c',$event)"
                            v-bind:disabled="checkedit" v-bind:class="!fatt.nomeLegRap?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CognomeLegRap')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('CognomeLegRap')"
                            v-model="fatt.cognomeLegRap" v-on:input="setField('Cognome_Rap_Legale__c',$event)"
                            v-bind:disabled="checkedit" v-bind:class="!fatt.cognomeLegRap?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('MailLegRap')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('MailLegRap')"
                            v-model="fatt.mailLegRap" v-on:input="setField('Mail_Rap_Legale__c',$event)"
                            v-bind:disabled="checkedit" v-bind:class="!fatt.mailLegRap || !regEmail.test(fatt.mailLegRap)?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="fatturante =='MeStesso'" class="col-xs-10 col-sm-10">
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('NomeCognome')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('NomeCognome')"
                          v-bind:value="$store.state.userprofile.Name" disabled="true" 
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CodiceFiscale')}}</label>
                      <input maxlength="16" type="text" required class="form-control" :placeholder="$t('CodiceFiscale')"
                          v-bind:value="$store.state.userprofile.CF__c" disabled="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">Email</label>
                      <input maxlength="100" type="text" required class="form-control" placeholder="Email"
                          v-bind:value="$store.state.userprofile.PersonEmail" disabled="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Indirizzo')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Indirizzo')"
                          v-bind:value="$store.state.userprofile.PersonMailingStreet" disabled="true"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Città')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Città')"
                          v-bind:value="$store.state.userprofile.PersonMailingCity" disabled="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CAP')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('CAP')"
                          v-bind:value="$store.state.userprofile.PersonMailingPostalCode" disabled="true"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Provincia')}}</label>
                      <input class="form-control" 
                        v-bind:value="$store.state.userprofile.PersonMailingState" disabled="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Paese')}}</label>
                      <input class="form-control" 
                        v-bind:value="$store.state.userprofile.PersonMailingCountry" disabled="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="fatturante =='PersonaFisica'" class="col-xs-10 col-sm-10">
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Nome')}}</label>
                        <input
                          v-model="fatt.nome"
                          maxlength="100"
                          required
                          v-bind:disabled="checkedit"
                          type="text"
                          class="form-control"
                          v-bind:class="!fatt.nome?'is-invalid':'is-valid'"
                          :placeholder="$t('Nome')"
                          v-on:input="setField('Nome_Fatturante__c',$event)"
                        />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Cognome')}}</label>
                        <input
                          v-model="fatt.cognome"
                          maxlength="100"
                          type="text"
                          required
                          v-bind:disabled="checkedit"
                          class="form-control"
                          v-bind:class="!fatt.cognome?'is-invalid':'is-valid'"
                          :placeholder="$t('Cognome')"
                          v-on:input="setField('Cognome_Fatturante__c',$event)"
                        />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CodiceFiscale')}}</label>
                      <input maxlength="16" type="text" required class="form-control" :placeholder="$t('CodiceFiscale')"
                          v-model="fatt.codicefiscale" v-on:input="setField('Codice_Fiscale__c',$event)"
                          v-bind:disabled="checkedit" v-bind:class="!fatt.codicefiscale?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('E-Mail')}}</label>
                      <input
                        v-model="fatt.email" 
                        v-on:input="setField('Email_Fatturante__c',$event)"
                        maxlength="100"
                        required
                        type="email"
                        class="form-control"
                        v-bind:class="!fatt.email || !regEmail.test(fatt.email)?'is-invalid':'is-valid'"
                        placeholder="Email" v-bind:disabled="checkedit"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Telefono')}}</label>
                      <input
                        v-model="fatt.telefono" 
                        v-on:input="setField('Telefono_Fatturante__c',$event)" 
                        maxlength="100"
                        required
                        type="tel"
                        class="form-control"
                        v-bind:class="!fatt.telefono?'is-invalid':'is-valid'"
                        :placeholder="$t('Telefono')" v-bind:disabled="checkedit"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Indirizzo')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Indirizzo')"
                          v-model="fatt.indirizzo" v-on:input="setField('BillingStreet',$event)"
                          v-bind:disabled="checkedit" v-bind:class="!fatt.indirizzo?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Città')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Città')"
                          v-model="fatt.citta" v-on:input="setField('BillingCity',$event)"
                          v-bind:disabled="checkedit" v-bind:class="!fatt.citta?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CAP')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('CAP')"
                          v-model="fatt.cap" v-on:input="setField('BillingPostalCode',$event)"
                          v-bind:disabled="checkedit" v-bind:class="!fatt.cap || !regNumber.test(fatt.cap)?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Provincia')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fatt.provincia" v-on:input="setField('BillingState',$event)"
                        v-bind:disabled="checkedit" v-bind:class="!fatt.provincia?'is-invalid':'is-valid'"
                      >
                        <option v-for="v in province" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Paese')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fatt.stato" v-on:input="setField('BillingCountry',$event)"
                        v-bind:disabled="checkedit" v-bind:class="!fatt.stato?'is-invalid':'is-valid'"
                      >
                        <option v-for="v in nazioni" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-10">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-10">
                    <h3>{{$t('DatidiNascita')}}</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                    <legend class="col-form-label">{{$t('Sesso')}}</legend>
                      <div class="form-check form-check-inline">
                        <input v-bind:class="!fatt.sesso?'is-invalid':'is-valid'" class="form-check-input" type="radio" name="sesso" value="F"
                          v-model="fatt.sesso" v-bind:disabled="checkedit" v-on:input="setField('Sesso_Fatturante__c','F','radio')"
                        />
                        <label class="form-check-label">F</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input v-bind:class="!fatt.sesso?'is-invalid':'is-valid'" class="form-check-input" type="radio" name="sesso" value="M"
                           v-model="fatt.sesso" v-bind:disabled="checkedit" v-on:input="setField('Sesso_Fatturante__c','M','radio')"                        
                        />
                        <label class="form-check-label">M</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                    <label class="control-label">{{$t('DatadiNascita')}}</label>
                      <input v-bind:class="!fatt.datanascita?'is-invalid':'is-valid'" type="date" required class="form-control" 
                        v-model="fatt.datanascita" v-bind:disabled="checkedit" v-on:input="setField('Data_Nascita_Fatturante__c',$event)"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CittàdiNascita')}}</label>
                      <input
                        v-bind:class="!fatt.cittanascita?'is-invalid':'is-valid'" v-model="fatt.cittanascita" v-on:input="setField('Citta_Nascita_Fatturante__c',$event)"
                        maxlength="100" type="text" v-bind:disabled="checkedit" required class="form-control" :placeholder="$t('Città')"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('ProvinciadiNascita')}}</label>
                      <select v-bind:class="!fatt.provincianascita?'is-invalid':'is-valid'" class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fatt.provincianascita" v-bind:disabled="checkedit" v-on:input="setField('Provincia_Nascita_Fatturante__c',$event)"
                      >
                        <option v-for="v in province" v-bind:disabled="checkedit" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('NazionediNascita')}}</label>
                      <select v-bind:class="!fatt.nazionenascita?'is-invalid':'is-valid'" class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fatt.nazionenascita" v-bind:disabled="checkedit" v-on:input="setField('Nazione_Nascita_Fatturante__c',$event)"
                      >
                        <option v-for="v in nazioni" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Cittadinanza')}}</label>
                      <select v-bind:class="!fatt.cittadinanzanascita1?'is-invalid':'is-valid'" class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fatt.cittadinanzanascita1" v-bind:disabled="checkedit" v-on:input="setField('Cittadinanza_Fatturante__c',$event)"
                      >
                        <option v-for="v in nazioni" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="$store.state.userprofile.Opportunit_con_richiesta_dati__c > 0">
                <div class="col-sm-12 col-md-5">
                </div>
                <div class="col-sm-12 col-md-5">
                  <button v-bind:disabled="checkconferma" type="submit" class="btn btn-primary" v-on:click.prevent="$emit('saveprofilo', 'FatturazioneCorsi'); inviato=true">{{$t('Conferma')}}</button>
                  &nbsp;
                  <button type="submit" class="btn btn-outline-secondary" v-on:click.prevent="$emit('cancel')">{{$t('Annulla')}}</button>
                </div>
              </div>
            </template>

            <template v-slot:fatturazioneHousing v-if="$store.state.opptyHousingFatt !== undefined">
              <h3>{{$t('DatidiFatturazione')}}</h3>
              <div v-if="$store.state.userprofile.Opportunit_Housing_con_richiesta_dati__c > 0 && $store.state.opptyHousingFatt.Fatturante_Housing_compilato__c == false" style="text-align: center; margin-bottom: 30px">
              <table style="width:100%">
                <tr>
                  <td style="width: 33%; padding: 0 20px 0 20px;"><label class="form-check-label fontRadioB">{{$t('MeStesso')}}</label></td>
                  <td style="width: 33%; padding: 0 20px 0 20px;"><label class="form-check-label fontRadioB">{{$t('PersonaGiuridica')}}</label></td>
                  <td style="width: 33%; padding: 0 20px 0 20px;"><label class="form-check-label fontRadioB">{{$t('PersonaFisica')}}</label></td>
                </tr>
                <tr>
                  <td style="width: 33%; padding: 0 20px 20px 20px;"><input v-on:click="setField('Tipo_persona_fatturazione_Housing__c', 'MeStesso', 'radio')" v-model="fatturanteHousing" class="form-check-input" type="radio"  value="MeStesso"/></td>
                  <td style="width: 33%; padding: 0 20px 20px 20px;"><input v-on:click="setField('Tipo_persona_fatturazione_Housing__c', 'PersonaGiuridica', 'radio')" v-model="fatturanteHousing" class="form-check-input" type="radio"  value="PersonaGiuridica"/></td>
                  <td style="width: 33%; padding: 0 20px 20px 20px;"><input v-on:click="setField('Tipo_persona_fatturazione_Housing__c', 'PersonaFisica', 'radio')" v-model="fatturanteHousing" class="form-check-input" type="radio"  value="PersonaFisica"/></td>
                </tr>
              </table>
              </div>
              <div v-if="fatturanteHousing =='PersonaGiuridica'" class="col-xs-10 col-sm-10">
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('RagioneSociale')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('RagioneSociale')"
                          v-model="fattHousing.ragioneSociale" v-on:input="setField('Ragione_Sociale_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.ragioneSociale?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('PartitaIVA')}}</label>
                      <input maxlength="20" type="text" required class="form-control" :placeholder="$t('P.IVA')"
                          v-model="fattHousing.piva" v-on:input="setField('PIVA_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.piva?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CodiceFiscale')}}</label>
                      <input maxlength="16" type="text" required class="form-control" :placeholder="$t('CodiceFiscale')"
                          v-model="fattHousing.codicefiscale" v-on:input="setField('Codice_Fiscale_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.codicefiscale?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">Email Pec</label>
                      <input maxlength="100" type="text" required class="form-control" placeholder="Email Pec"
                          v-model="fattHousing.pec" v-on:input="setField('PEC_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.pec || !regEmail.test(fattHousing.pec)?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CodiceSDI')}}</label>
                      <input maxlength="16" type="text" required class="form-control" :placeholder="$t('FatturazioneElettronica')"
                          v-model="fattHousing.codicesdi" v-on:input="setField('Codice_SDI_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.codicesdi?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Indirizzo')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Indirizzo')"
                          v-model="fattHousing.indirizzo" v-on:input="setField('Indirizzo_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.indirizzo?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Città')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Città')"
                          v-model="fattHousing.citta" v-on:input="setField('Citta_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.citta?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CAP')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('CAP')"
                          v-model="fattHousing.cap" v-on:input="setField('CAP_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.cap || !regNumber.test(fattHousing.cap)?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Provincia')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fattHousing.provincia" v-on:input="setField('Provincia_Housing__c',$event)"
                        v-bind:disabled="checkeditH" v-bind:class="!fattHousing.provincia?'is-invalid':'is-valid'"
                      >
                        <option v-for="v in province" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Paese')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fattHousing.stato" v-on:input="setField('Paese_Housing__c',$event)"
                        v-bind:disabled="checkeditH" v-bind:class="!fattHousing.stato?'is-invalid':'is-valid'"
                      >
                        <option v-for="v in nazioni" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="fatturanteHousing =='MeStesso'" class="col-xs-10 col-sm-10">
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('NomeCognome')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('NomeCognome')"
                          v-bind:value="$store.state.userprofile.Name" disabled="true"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CodiceFiscale')}}</label>
                      <input maxlength="16" type="text" required class="form-control" :placeholder="$t('CodiceFiscale')"
                          v-bind:value="$store.state.userprofile.CF__c" disabled="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">Email</label>
                      <input maxlength="100" type="text" required class="form-control" placeholder="Email"
                          v-bind:value="$store.state.userprofile.PersonEmail" disabled="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Indirizzo')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Indirizzo')"
                          v-bind:value="$store.state.userprofile.PersonMailingStreet" disabled="true"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Città')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Città')"
                          v-bind:value="$store.state.userprofile.PersonMailingCity" disabled="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CAP')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('CAP')"
                          v-bind:value="$store.state.userprofile.PersonMailingPostalCode" disabled="true"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Provincia')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-bind:value="$store.state.userprofile.PersonMailingState" disabled="true"
                      >
                        <option v-for="v in province" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Paese')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-bind:value="$store.state.userprofile.PersonMailingCountry" disabled="true"
                      >
                        <option v-for="v in nazioni" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="fatturanteHousing =='PersonaFisica'" class="col-xs-10 col-sm-10">
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Nome')}}</label>
                        <input
                          v-model="fattHousing.nome"
                          maxlength="100"
                          required
                          type="text"
                          class="form-control"
                          v-bind:class="!fattHousing.nome?'is-invalid':'is-valid'"
                          :placeholder="$t('Nome')"
                          v-on:input="setField('Nome_Fatturante_Housing__c',$event)"
                          v-bind:disabled="checkeditH"
                        />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Cognome')}}</label>
                        <input
                          v-model="fattHousing.cognome"
                          maxlength="100"
                          type="text"
                          required
                          class="form-control"
                          v-bind:class="!fattHousing.cognome?'is-invalid':'is-valid'"
                          :placeholder="$t('Cognome')"
                          v-on:input="setField('Cognome_Fatturante_Housing__c',$event)"
                          v-bind:disabled="checkeditH"
                        />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CodiceFiscale')}}</label>
                      <input maxlength="16" type="text" required class="form-control" :placeholder="$t('CodiceFiscale')"
                          v-model="fattHousing.codicefiscale" v-on:input="setField('Codice_Fiscale_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.codicefiscale?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Indirizzo')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Indirizzo')"
                          v-model="fattHousing.indirizzo" v-on:input="setField('Indirizzo_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.indirizzo?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Città')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Città')"
                          v-model="fattHousing.citta" v-on:input="setField('Citta_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.citta?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CAP')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('CAP')"
                          v-model="fattHousing.cap" v-on:input="setField('CAP_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.cap || !regNumber.test(fattHousing.cap)?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Provincia')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fattHousing.provincia" v-on:input="setField('Provincia_Housing__c',$event)"
                        v-bind:disabled="checkeditH" v-bind:class="!fattHousing.provincia?'is-invalid':'is-valid'"
                      >
                        <option v-for="v in province" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Paese')}}</label>
                      <select class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fattHousing.stato" v-on:input="setField('Paese_Housing__c',$event)"
                        v-bind:disabled="checkeditH" v-bind:class="!fattHousing.stato?'is-invalid':'is-valid'"
                      >
                        <option v-for="v in nazioni" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-10">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-10">
                    <h3>{{$t('DatidiNascita')}}</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                    <legend class="col-form-label">{{$t('Sesso')}}</legend>
                      <div class="form-check form-check-inline">
                        <input v-bind:class="!fattHousing.sesso?'is-invalid':'is-valid'" class="form-check-input" type="radio" name="sesso" value="F"
                           v-model="fattHousing.sesso" v-on:input="setField('Sesso_Fatturante_Housing__c','F','radio')" v-bind:disabled="checkeditH"
                        />
                        <label class="form-check-label">F</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input v-bind:class="!fattHousing.sesso?'is-invalid':'is-valid'" class="form-check-input" type="radio" name="sesso" value="M"
                          v-model="fattHousing.sesso" v-on:input="setField('Sesso_Fatturante_Housing__c','M','radio')"  v-bind:disabled="checkeditH"                      
                        />
                        <label class="form-check-label">M</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                    <label class="control-label">{{$t('DatadiNascita')}}</label>
                      <input v-bind:class="!fattHousing.datanascita?'is-invalid':'is-valid'" type="date" required class="form-control" 
                        v-model="fattHousing.datanascita" v-bind:disabled="checkeditH" v-on:input="setField('Data_Nascita_Fatturante_Housing__c',$event)"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('CittàdiNascita')}}</label>
                      <input
                        v-bind:class="!fattHousing.cittanascita?'is-invalid':'is-valid'" v-model="fattHousing.cittanascita" v-on:input="setField('Citta_Nascita_Fatturante_Housing__c',$event)"
                        maxlength="100" type="text" v-bind:disabled="checkeditH" required class="form-control" :placeholder="$t('Città')"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('ProvinciadiNascita')}}</label>
                      <select v-bind:class="!fattHousing.provincianascita?'is-invalid':'is-valid'" class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fattHousing.provincianascita" v-bind:disabled="checkeditH" v-on:input="setField('Provincia_Nascita_Fatturante_Housing__c',$event)"
                      >
                        <option v-for="v in province" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('NazionediNascita')}}</label>
                      <select v-bind:class="!fattHousing.nazionenascita?'is-invalid':'is-valid'" class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fattHousing.nazionenascita" v-bind:disabled="checkeditH" v-on:input="setField('Nazione_Nascita_Fatturante_Housing__c',$event)"
                      >
                        <option v-for="v in nazioni" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Cittadinanza')}}</label>
                      <select v-bind:class="!fattHousing.cittadinanzanascita2?'is-invalid':'is-valid'" class="form-control" :title="$t('Scegliunaopzione')" required
                        v-model="fattHousing.cittadinanzanascita2" v-bind:disabled="checkeditH" v-on:input="setField('Cittadinanza_Fatturante_Housing__c',$event)"
                      >
                        <option v-for="v in nazioni" v-bind:key="v" v-bind:value="v">{{v}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
                <div class="row">
                  <div class="col-sm-12 col-md-10">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-10">
                    <h3>{{$t('DatiCauzione')}}</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('Intestatario')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('Intestatario')"
                          v-model="fattHousing.intestatario" v-on:input="setField('Intestatario_IBAN_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.intestatario?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group">
                      <label class="control-label">{{$t('IBAN')}}</label>
                      <input maxlength="100" type="text" required class="form-control" :placeholder="$t('IBAN')"
                          v-model="fattHousing.iban" v-on:input="setField('Iban_Housing__c',$event)"
                          v-bind:disabled="checkeditH" v-bind:class="!fattHousing.iban?'is-invalid':'is-valid'"
                      />
                    </div>
                  </div>
                </div>
              <div class="row" v-if="$store.state.userprofile.Opportunit_Housing_con_richiesta_dati__c > 0">
                <div class="col-sm-12 col-md-5">
                </div>
                <div class="col-sm-12 col-md-5">
                  <button v-bind:disabled="checkconfermaHousing" type="submit" class="btn btn-primary" v-on:click.prevent="$emit('saveprofilo', 'FatturazioneHousing'); inviatoHousing=true">{{$t('Conferma')}}</button>
                  &nbsp;
                  <button type="submit" class="btn btn-outline-secondary" v-on:click.prevent="$emit('cancel')">{{$t('Annulla')}}</button>
                </div>
              </div>
            </template>

            <template v-slot:logout>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <button type="submit" class="btn btn-primary float-right" v-on:click.prevent="doLogout">Logout</button>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    {{$t('Disconnessione')}}
                  </div>
                </div>
            </template>

          </tabs>
    </div>
  </div>
  </div>


</template>

<script>
import tabs from "@/components/tabs.vue";
// import moment from "moment";

export default {
    name: 'datipersonali',
    components: {
      tabs
    },
    props: {},
    computed: {
      datiestesi: function() {
        return this.$store.getters.candidato || this.$store.getters.studente || this.$store.getters.diplomato;
      },
      province: function() {
        return this.getPicklistValues('Lead','Provincia_Picklist__c');
      },
      nazioni: function() {
        return this.getPicklistValues('Lead','Nazione_Picklist__c');
      },
      titoli: function() {
        return this.getPicklistValues('Account','Titolo_di_studio__c');
      },
      conosciutoalma: function() {
        return this.getPicklistValues('Account','ConosciutoALMA__c');
      },
      checkeditH: function(){
        return this.$store.state.userprofile.Opportunit_Housing_con_richiesta_dati__c === 0 || this.$store.state.opptyHousingFatt.Fatturante_Housing_compilato__c === true || this.inviatoHousing === true;
      },
      checkedit: function(){
        return this.$store.state.userprofile.Opportunit_con_richiesta_dati__c === 0 || this.$store.state.candidatura[0].Fatturante_compilato__c === true || this.inviato === true;
      },
      checkconferma: function(){
        if(this.$store.state.candidatura[0].Fatturante_compilato__c) return true;
        if(this.inviato) return true;
        if(this.fatturante != 'MeStesso'){
          if(this.fatturante == 'PersonaGiuridica' && !this.fatt.ragioneSociale) return true;
          if(this.fatturante == 'PersonaGiuridica' && !this.fatt.piva) return true;
          if(this.fatturante == 'PersonaFisica' && !this.fatt.nome) return true;
          if(this.fatturante == 'PersonaFisica' && !this.fatt.cognome) return true;
          if(!this.fatt.codicefiscale) return true;
          if(this.fatturante == 'PersonaGiuridica' && !this.fatt.pec) return true;
          if(this.fatturante == 'PersonaGiuridica' && !this.fatt.codicesdi) return true;
          if(!this.fatt.indirizzo) return true;
          if(!this.fatt.citta) return true;
          if(!this.fatt.cap) return true;
          if(!this.fatt.provincia) return true;
          if(!this.fatt.stato) return true;
          if(this.fatturante == 'PersonaFisica' && !this.fatt.sesso) return true;
          if(this.fatturante == 'PersonaFisica' && !this.fatt.datanascita) return true;
          if(this.fatturante == 'PersonaFisica' && !this.fatt.cittanascita) return true;
          if(this.fatturante == 'PersonaFisica' && !this.fatt.provincianascita) return true;
          if(this.fatturante == 'PersonaFisica' && !this.fatt.nazionenascita) return true;
          if(this.fatturante == 'PersonaFisica' && !this.fatt.cittadinanzanascita1) return true;
        }

        if(this.fatturante == 'PersonaGiuridica') {
          if(!this.fatt.nomeLegRap) return true
          if(!this.fatt.cognomeLegRap) return true
          if(!this.fatt.mailLegRap) return true
        }

        return false;
      },
      checkconfermaHousing: function(){
        if(this.$store.state.opptyHousingFatt.Fatturante_Housing_compilato__c) return true;
        if(this.inviatoHousing) return true;
        if(this.fatturanteHousing != 'MeStesso'){
          if(this.fatturanteHousing == 'PersonaGiuridica' && !this.fattHousing.ragioneSociale) return true;
          if(this.fatturanteHousing == 'PersonaFisica' && !this.fattHousing.nome) return true;
          if(this.fatturanteHousing == 'PersonaFisica' && !this.fattHousing.cognome) return true;
          if(this.fatturanteHousing == 'PersonaGiuridica' && !this.fattHousing.piva) return true;
          if(!this.fattHousing.codicefiscale) return true;
          if(this.fatturanteHousing == 'PersonaGiuridica' && !this.fattHousing.pec) return true;
          if(this.fatturanteHousing == 'PersonaGiuridica' && !this.fattHousing.codicesdi) return true;
          if(!this.fattHousing.indirizzo) return true;
          if(!this.fattHousing.citta) return true;
          if(!this.fattHousing.cap) return true;
          if(!this.fattHousing.provincia) return true;
          if(!this.fattHousing.stato) return true;
          if(this.fatturanteHousing == 'PersonaFisica' && !this.fattHousing.sesso) return true;
          if(this.fatturanteHousing == 'PersonaFisica' && !this.fattHousing.datanascita) return true;
          if(this.fatturanteHousing == 'PersonaFisica' && !this.fattHousing.cittanascita) return true;
          if(this.fatturanteHousing == 'PersonaFisica' && !this.fattHousing.provincianascita) return true;
          if(this.fatturanteHousing == 'PersonaFisica' && !this.fattHousing.nazionenascita) return true;
          if(this.fatturanteHousing == 'PersonaFisica' && !this.fattHousing.cittadinanzanascita2) return true;
        }
        if(!this.fattHousing.iban) return true;
        if(!this.fattHousing.intestatario) return true;
        return false;
      }

    },
    mounted: function() {
      this.setTabs();
      this.setuserdata();
      console.log('dati personali mounted')
      this.user.username=this.$store.state.userprofile.Username__pc;
      this.user.password=this.$store.state.userprofile.Password__pc;
      this.user.email=this.$store.state.userprofile.PersonEmail;
      this.$store.dispatch('getCandidatura');
      console.log('CI SIAMO', this.$store.state.userprofile.Consenso_notifiche__c);
      if(this.$store.state.userprofile.Consenso_notifiche__c){
        this.notifiche = 'DisattivaNotifiche';
      }
      else{
        this.notifiche = 'AttivaNotifiche';
      }
    },
    data: function () {
      return {
        regEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        regNumber:/^[0-9]+$/,
        inviato: false,
        inviatoHousing: false,
        cambiaemail: false,
        cambiapassword: false,
        tabDecision: 'DatiAccesso',
        show: false,
        reveal: false,
        tabs: [],
        fatturante:'MeStesso',
        fatturanteHousing:'MeStesso',
        fatt: {
          nome:'',
          cognome:'',
          ragioneSociale: '',
          piva: '',
          codicefiscale:'',
          codicesdi:'',
          pec:'',
          email:'',
          telefono:'',
          indirizzo:'',
          citta:'',
          provincia:'',
          cap:'',
          stato:'',
          sesso:'',
          datanascita:'',
          cittanascita:'',
          provincianascita:'',
          nazionenascita:'',
          cittadinanzanascita1:'',

          nomeLegRap:'',
          cognomeLegRap:'',
          mailLegRap:'',
        },
        fattHousing: {
          nome:'',
          cognome:'',
          ragioneSociale: '',
          piva: '',
          codicefiscale:'',
          codicesdi:'',
          pec:'',
          email:'',
          telefono:'',
          indirizzo:'',
          citta:'',
          provincia:'',
          cap:'',
          stato:'',
          iban:'',
          intestatario:'',
          sesso:'',
          datanascita:'',
          cittanascita:'',
          provincianascita:'',
          nazionenascita:'',
          cittadinanzanascita2:'',
        },
        user: {
          email:'',
          email2:'',

          emailnew:'',
          emailnew2:'',

          pwdold:'',
          pwdnew:'',
          pwdnew2:'',

          username:'',
          password:'',
          privacy: false
        },
        publicVapidKey: 'BBOBTmsYjl2SNkwbKddi0stTi0EOJPfxKETo_1CiX3P8VxRQ3nSozxtt3gRQmVX7ZJzsAM0eQfqn5flxZYpv8nc',
        notifiche: ''
      }
    },
    watch: {
      datiestesi: function() {
        this.setTabs();
      }
    },
    methods: {
      chgReveal: function() {
        this.reveal=!this.reveal;
      },
      checkPwdOld: function() {
        if (!this.user.pwdold) return true;
        if (this.user.pwdold!=this.$store.state.userprofile.Password__pc) return true;
        return false;
      },
      checkPwd: function() {
        if (!this.user.pwdnew) return false;
        if (!this.user.pwdnew2) return false;
        if (this.user.pwdnew==this.$store.state.userprofile.Password__pc) return true;
        if (this.user.pwdnew==this.user.pwdnew2) return false;
        return true;
      },
      doCambiaPwd: function() {
        if (this.user.pwdnew!=this.user.pwdnew2) return;
        this.cambiapassword=false;
        console.log('this.user.pwdnew', this.user.pwdnew)
        this.$store.commit('SET_USERPROFILE_FIELD', { name: 'Password__pc', val: this.user.pwdnew} );
        this.$emit('saveprofilo');
      },
      checkEmail: function() {
        if (!this.user.emailnew) return false;
        if (!this.user.emailnew2) return false;
        if (this.user.emailnew==this.user.emailnew2) return false;
        return true;
      },
      doCambiaEmail: function() {
        if (this.user.emailnew!=this.user.emailnew2) return;
        this.cambiaemail=false;
        this.$store.commit('SET_USERPROFILE_FIELD', { name: 'PersonEmail', val: this.user.emailnew} );
        this.$emit('saveprofilo');
      },
      getPicklistValues: function(o,f) {
        if (this.$store.state.campi && this.$store.state.campi.has) {
          if (this.$store.state.campi.has(o)) {
            var af=this.$store.state.campi.get(o)
            if (af.has(f)) {
              var afv=af.get(f);
              if (afv) return afv.values.split(';');
            }
          }
        }
        return [];
      },
      setTabs: function() {
        if(this.$i18n.locale=='it'){
          this.show = true;
        }
        else{
          this.show = false;
        }
        if(this.tabDecision == 'DatiAccesso'){
          this.tabs=[
            { title: this.$i18n.t("Dati Accesso"), name: "dlogin" },
            { title: this.$i18n.t("Disconnessione"), name: "logout" }
          ];
        }
        if(this.tabDecision == 'DatiAnagrafici'){
          if (this.datiestesi) {
            this.tabs=[
              { title: this.$i18n.t("DatiPersonali"), name: "dpersonali" },
              { title: this.$i18n.t("DatidiNascita"), name: "dnascita" },
              { title: this.$i18n.t("Residenza"), name: "residenza" },
              { title: this.$i18n.t("Studi"), name: "studi" }
            ];
          }else{
            this.tabs=[
              { title: this.$i18n.t("DatiPersonali"), name: "dpersonali" },
            ];
          }
        }
        console.log(this.$store.state.opptyHousingFatt);
        if(this.tabDecision == 'Fatturazione'){
          if(this.$store.state.opptyHousingFatt != "" && Object.keys(this.$store.state.opptyHousingFatt).length !== 0){
            this.tabs=[
              { title: this.$i18n.t("Fatturazione Corsi"), name: "fatturazione" },
              { title: this.$i18n.t("Fatturazione Housing"), name: "fatturazioneHousing" }
            ];
          }else{
            this.tabs=[
              { title: this.$i18n.t("Fatturazione Corsi"), name: "fatturazione" }
            ];
          }
        }
      },
      setField: function(f,e,t) {
        this.$store.commit('SET_USERPROFILE_DURTY', true );
        if (t!=undefined && t=='radio') {
          this.$store.commit('SET_USERPROFILE_FIELD', { name: f, val: e} );
          return;
        }
        this.$store.commit('SET_USERPROFILE_FIELD', { name: f, val: e.target.value} );
      },
      doLogout: function() {
        this.$store.commit('RESET_USER_DATA');
      },
      setuserdata: function() {
        console.log('setuserdata');
          this.fatt.nome = this.$store.state.userprofile.Nome_Fatturante__c;
          this.fatt.cognome = this.$store.state.userprofile.Cognome_Fatturante__c;
          this.fatt.ragioneSociale = this.$store.state.userprofile.Ragione_Sociale__c;
          this.fatt.piva=this.$store.state.userprofile.PIVA_Fatturante__c;
          this.fatt.codicefiscale=this.$store.state.userprofile.Codice_Fiscale__c;
          this.fatt.codicesdi=this.$store.state.userprofile.Codice_SDI__c;
          this.fatt.pec=this.$store.state.userprofile.PEC__c;
          this.fatt.email=this.$store.state.userprofile.Email_Fatturante__c;
          this.fatt.telefono=this.$store.state.userprofile.Telefono_Fatturante__c;
          this.fatt.indirizzo=this.$store.state.userprofile.BillingStreet;
          this.fatt.citta=this.$store.state.userprofile.BillingCity;
          this.fatt.provincia=this.$store.state.userprofile.BillingState;
          this.fatt.cap=this.$store.state.userprofile.BillingPostalCode;
          this.fatt.stato=this.$store.state.userprofile.BillingCountry;
          this.fatt.sesso=this.$store.state.userprofile.Sesso_Fatturante__c;
          this.fatt.datanascita=this.$store.state.userprofile.Data_Nascita_Fatturante__c;
          this.fatt.cittanascita=this.$store.state.userprofile.Citta_Nascita_Fatturante__c;
          this.fatt.provincianascita=this.$store.state.userprofile.Provincia_Nascita_Fatturante__c;
          this.fatt.nazionenascita=this.$store.state.userprofile.Nazione_Nascita_Fatturante__c;
          this.fatt.cittadinanzanascita1=this.$store.state.userprofile.Cittadinanza_Fatturante__c;
          this.fatt.nomeLegRap=this.$store.state.userprofile.Nome_Rap_Legale__c;
          this.fatt.cognomeLegRap=this.$store.state.userprofile.Cognome_Rap_Legale__c;
          this.fatt.mailLegRap=this.$store.state.userprofile.Mail_Rap_Legale__c;
          this.fattHousing.nome = this.$store.state.userprofile.Nome_Fatturante_Housing__c;
          this.fattHousing.cognome = this.$store.state.userprofile.Cognome_Fatturante_Housing__c;
          this.fattHousing.ragioneSociale=this.$store.state.userprofile.Ragione_Sociale_Housing__c;
          this.fattHousing.piva=this.$store.state.userprofile.PIVA_Housing__c;
          this.fattHousing.codicefiscale=this.$store.state.userprofile.Codice_Fiscale_Housing__c;
          this.fattHousing.codicesdi=this.$store.state.userprofile.Codice_SDI_Housing__c;
          this.fattHousing.pec=this.$store.state.userprofile.PEC_Housing__c;
          this.fattHousing.email=this.$store.state.userprofile.Email_Fatturante_Housing__c;
          this.fattHousing.telefono=this.$store.state.userprofile.Telefono_Fatturante_Housing__c;
          this.fattHousing.indirizzo=this.$store.state.userprofile.Indirizzo_Housing__c;
          this.fattHousing.citta=this.$store.state.userprofile.Citta_Housing__c;
          this.fattHousing.provincia=this.$store.state.userprofile.Provincia_Housing__c;
          this.fattHousing.cap=this.$store.state.userprofile.CAP_Housing__c;
          this.fattHousing.stato=this.$store.state.userprofile.Paese_Housing__c;
          this.fattHousing.iban=this.$store.state.userprofile.Iban_Housing__c;
          this.fattHousing.intestatario=this.$store.state.userprofile.Intestatario_IBAN_Housing__c;
          this.fattHousing.sesso=this.$store.state.userprofile.Sesso_Fatturante_Housing__c;
          this.fattHousing.datanascita=this.$store.state.userprofile.Data_Nascita_Fatturante_Housing__c;
          this.fattHousing.cittanascita=this.$store.state.userprofile.Citta_Nascita_Fatturante_Housing__c;
          this.fattHousing.provincianascita=this.$store.state.userprofile.Provincia_Nascita_Fatturante_Housing__c;
          this.fattHousing.nazionenascita=this.$store.state.userprofile.Nazione_Nascita_Fatturante_Housing__c;
          this.fattHousing.cittadinanzanascita2=this.$store.state.userprofile.Cittadinanza_Fatturante_Housing__c;
          this.fatturante=this.$store.state.userprofile.Tipo_persona_fatturazione__c? this.$store.state.userprofile.Tipo_persona_fatturazione__c:'MeStesso';
          this.fatturanteHousing=this.$store.state.userprofile.Tipo_persona_fatturazione_Housing__c? this.$store.state.userprofile.Tipo_persona_fatturazione_Housing__c:'MeStesso';
        console.log(this.fatt);
        console.log(this.fattHousing);
        console.log(this.fatturante);
        console.log(this.fatturanteHousing);
      },
      beforerun: async function() {
        console.log(navigator);

        if(!this.$store.state.userprofile.Consenso_notifiche__c){

          if ('serviceWorker' in navigator || !navigator.serviceWorker) {
            console.log('Registering service worker');

            if (confirm('notifiche?')) {
                this.run().catch(error => {
                    console.error(error)
                    // alert(JSON.stringify(error));
                });
              }
          } else {
              alert('no serviceworker??')
          }

        }else{
          this.unsubscribePush();
        }
      },

      run: async function() {
        console.log('Registering service worker', navigator.serviceWorker);
        const registration = await navigator.serviceWorker.register('/worker.js', {scope: '/'});
        console.log('Registered service worker');

        console.log('Current subs');
        let subs=await registration.pushManager.getSubscription();
        console.log(subs);

        if (subs) {
          console.log('unsubscribe');
          let successful=await subs.unsubscribe();
          console.log(successful);
        }

        console.log('Registering push');
        var subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array(this.publicVapidKey),
        });


        console.log('Sending push');
        this.$store.dispatch('subscribePush', {details: subscription, AccountId: this.$store.state.userprofile.Id});

        this.notifiche = 'DisattivaNotifiche';
        this.$store.state.userprofile.Consenso_notifiche__c = !this.$store.state.userprofile.Consenso_notifiche__c;
        console.log('Sent push');
      },

      unsubscribePush: async function(){
        this.$store.dispatch('unsubscribePush', {AccountId: this.$store.state.userprofile.Id});
        this.notifiche = 'AttivaNotifiche';
        this.$store.state.userprofile.Consenso_notifiche__c = !this.$store.state.userprofile.Consenso_notifiche__c;
      },

      urlBase64ToUint8Array: function(base64String) {
          console.log('Entro', base64String);
          var padding = '='.repeat((4 - base64String.length % 4) % 4);
          console.log('Dopo entro');
          var base64 = (base64String + padding)
              .replace(/-/g, '+')
              .replace(/_/g, '/');

          var rawData = window.atob(base64);
          var outputArray = new Uint8Array(rawData.length);

          for (var i = 0; i < rawData.length; ++i) {
              outputArray[i] = rawData.charCodeAt(i);
          }
          return outputArray;
      }
    }
  }
</script>

<style scoped>
</style>